import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide15/image/imgPortadaGuia15.png"
import img1 from "@components/pageGuide/guides/guide15/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide15/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide15/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide15/image/silder3.png"

const title = (
  <>
   <h1 className="titles-underlined-ebook ebook-ventas">
      ¿Cómo mandar mensajes masivos por WhatsApp?
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Desde la preparación de mensajes, hasta las herramientas que debes tener
      para enviar mensajes masivos por WhatsApp. En esta guía aprenderás todos
      los pasos que debes seguir para crear este tipo de campañas.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Esta guía utilizaremos a nuestra plataforma Beex para que puedas seguir
    paso a paso cómo enviar mensajes masivos por WhatsApp de forma eficiente y
    sin infringir las políticas de uso de la plataforma.
    <br /> <br />
    Ya sea que estés promocionando un producto, difundiendo información
    importante, esta guía te proporcionará los conocimientos necesarios para
    aprovechar al máximo el alcance de WhatsApp como una poderosa herramienta de
    comunicación.
  </p>
)

const data = {
  start: {
    support: "Guía | WhatsApp ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Cómo conseguir la API de WhatsApp Business.",
      },
      {
        key: 2,
        text: "Alistar la base de datos de los clientes.",
      },
      {
        key: 3,
        text: "Crean plantillas de mensaje efectivas.",
      },
      {
        key: 4,
        text: "Configurar el envío de mensajes desde Beex.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
