import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide15/dataPageGuide15"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        ¿Cómo mandar mensajes masivos por WhatsApp?
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/como-mandar-mensaje-masivo-whatsapp/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Si no sabías cómo mandar mensajes masivos por WhatsApp, entonces nuestra guía te ayudará con toda la información que estás buscando."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="¿Cómo mandar mensajes masivos por WhatsApp?"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/como-mandar-mensaje-masivo-whatsapp/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia15.jpg`}
      />
      <meta
        property="og:description"
        content="Si no sabías cómo mandar mensajes masivos por WhatsApp, entonces nuestra guía te ayudará con toda la información que estás buscando."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="WhatsApp"
      location={location}
      typeGuide="guide15"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
